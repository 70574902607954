import { HistoryInterface, TableHeader } from '@data/iterfaces/api/history.interface';
import { HistoryService } from './../../../data/services/api/protected/history/history.service';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserService } from '@data/services/api/protected/user.service';
import { User, UserSelectedInterface } from '@data/iterfaces/api/userSelected.interface';
import { QuoterResponse } from '@data/iterfaces/api/quoterpy';
import { UiService } from '@shared/services/ui.service';
import { ParamsHistoryInterface } from '@data/iterfaces/api/paramsHistory.interface';

@Component({
  selector: 'app-historical-table',
  templateUrl: './historical-table.component.html',
  styleUrls: ['./historical-table.component.sass']
})
export class HistoricalTableComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | any;
  @ViewChild(MatSort, { static: false }) sort: MatSort | any;
  @ViewChild('input', { static: false }) input: ElementRef<HTMLInputElement> | undefined;
  @Output() finalQuoteEvent = new EventEmitter<any>();
  responseHistory!: HistoryInterface | null;
  response!: QuoterResponse | null;
  dataSource!: MatTableDataSource<HistoryInterface>;

  public userRolId!: number | null;
  public userId!: string;
  public columns!: TableHeader[];
  public selected = 'none';
  public canShow: boolean = false;
  public panelOpenState: boolean = false;
  public isLoading: boolean = false;
  public filterValue: string = '';
  userSelected!: UserSelectedInterface | null;
  users!: User[];
  params: ParamsHistoryInterface = {
    user: null,
    search: null,
    sort: 'id',
    direction: 'desc',
    limit: 5,
    page: 1,
  }

  constructor(
    private historyService: HistoryService,
    private userService: UserService,
    private change: ChangeDetectorRef,
    private uiService: UiService
  ) { }

  async ngOnInit(): Promise<void> {
    this.userId = localStorage.getItem('user_id') || '';
    const rol: any = localStorage.getItem('rolId');
    this.userRolId = +rol;
    await this.initializeData();
    await this.getUsers();
  }

  async initializeData() {
    this.params.user = this.selected != 'none' ? +this.selected : null;
    this.responseHistory = await this.historyService.index(this.params);
    this.isLoading = this.responseHistory.ok;
    if (!this.isLoading) return this.uiService.error('Error al obtener datos.');
    this.columns = this.responseHistory.data.tableHeaders;
    this.setAccountData(this.responseHistory.data.rows);
    this.panelOpenState = this.responseHistory.ok;
  }

  setAccountData = (data: any): any => {
    if (!this.columns) {
      setTimeout(() => this.setAccountData(data), 15);
    } else {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  getColumns = (): string[] => {
    if (!this.columns) {
      setTimeout(() => this.getColumns(), 15);
      return [];
    } else {
      return this.columns.map(column => column.value)
    }
  }

  applyFilter(filterValue: any) {
    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  async getUsers() {
    this.userSelected = await this.userService.getAllUsersDeadlines('historical');
    this.users = this.userSelected.users;
  }


  async filterTable(event: Event, reset: boolean = false): Promise<void> {
    if (reset) {
      this.resetFilter();
      return;
    }

    this.filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filter = this.filterValue;

    if (this.dataSource.filteredData.length === 0) {
      this.params.search = this.filterValue || null;
      await this.initializeData();
    } else if (this.filterValue == '') {
      this.params.search = null;
      this.selected = 'none';
      await this.initializeData();
    }

    this.change.detectChanges();
  }

  async pageChanged(event: PageEvent) {
    this.params.limit = event.pageSize;
    await this.initializeData();
  }

  resetFilter(): void {
    this.filterValue = '';
    this.dataSource.filter = '';
    this.change.detectChanges();
    this.resetInputValue();
  }

  resetInputValue(): void {
    if (this.input && this.input.nativeElement) {
      this.input.nativeElement.value = '';
    }
  }

  async generateQuoter(value: any) {
    this.response = await this.historyService.generateQuoter(value.id);
    this.panelOpenState = false;

    this.finalQuoteEvent.emit({
      data: this.response,
      user: value.valuesQuoter.userId ? +value.valuesQuoter.userId : null
    });
    if (this.response.ok) {
      this.uiService.success('Cotización generada.');
    }
  }
}
