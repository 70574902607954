import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { constantI, requestQuoterI } from '@data/iterfaces/api/quoter';
import { QuoterService } from '@data/services/api/protected/quoter.service';
import { UiService } from '@shared/services/ui.service';

@Component({
  selector: 'app-quoter-report',
  templateUrl: './quoter-report.component.html',
  styleUrls: ['./quoter-report.component.sass']
})
export class QuoterReportComponent implements OnInit {
  @Output() finalQuoteEvent = new EventEmitter<any>();
  staticVehicleInfo!: any;
  userRolId!: any;

  ivaC = 0.16;
  /* Dinamic */
  displayedColumnsTasas: string[] = ['meses', 'tasaActiva'];
  TASA_ACTIVA_RENTA: any;
  dataSourceTA!: MatTableDataSource<constantI>;

  displayedColumnsResidual: string[] = ['meses', 'valorResidual'];
  VALOR_RESIDUAL: any;
  dataSourceVR!: MatTableDataSource<constantI>;

  /* Static */
  displayedColumnsTasaRenta: string[] = ['meses', 'renta', 'irr', 'tasaActivaSeguro'];
  TASA_ACTIVA_SEGURO: any;
  dataSourceTAS: any;

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private quoterService: QuoterService,
    private uiService: UiService
  ) { }

  formCotizador: FormGroup = this.formBuilder.group({
    precioOriginal: [null, [Validators.required]], //464900
    marca: [null],
    modelo: [null],
    anio: [null],
    precioApex: [null], //400776
    precioPublico: [null], //400776
    iva: [null],
    irrminimo: [null],
    udi: [null],
    comision: [null],
    primaOriginal: [null, [Validators.required]], //10000
    primaTotal: [null], //8620.69
  });

  formReverseQuote: FormGroup = this.formBuilder.group({
    pago_inicial: [null, [Validators.required]]
  });

  vehicleCost: number = 0;

  applyIva(filterValue: any, variablesNum: number) {
    let formValues = {};
    if (variablesNum == 0) {
      this.vehicleCost = parseFloat(filterValue);
      let precioApex = Math.round(parseFloat(filterValue) / (1 + this.ivaC));
      formValues = { precioApex: precioApex, precioPublico: precioApex }
    } else if (variablesNum == 1) {
      let primaTotal = this.roundTwoDecimals(parseFloat(filterValue) / (1 + this.ivaC));
      formValues = { primaTotal: primaTotal }
    }
    this.formCotizador.patchValue(formValues);
  }

  ngOnInit(): void {
    this.setConstants();
    this.userRolId = localStorage.getItem('rolId');
    this.userRolId = parseInt(this.userRolId);
  }

  async generateQuote() {
    this.staticVehicleInfo = {
      marca: this.formCotizador.controls['marca'].value,
      modelo: this.formCotizador.controls['modelo'].value,
      anio: this.formCotizador.controls['anio'].value,
      precio: `$${this.formCotizador.controls['precioOriginal'].value}`,
      preioApex: this.formCotizador.controls['precioApex'].value,
      seguro: this.formCotizador.controls['primaOriginal'].value,
      seguroTotal: this.formCotizador.controls['primaTotal'].value
    };
    if (this.formCotizador.valid) {
      this.uiService.loading(true, 'Generando Cotización');
      let data: requestQuoterI = {
        original_loan: this.formCotizador.controls['precioOriginal'].value,
        loan: this.formCotizador.controls['precioApex'].value,
        prima: this.formCotizador.controls['primaTotal'].value,
        original_prima: this.formCotizador.controls['primaOriginal'].value
      };

      let quoteData: any = await this.quoterService.generateQuote(data);
      if (quoteData?.ok) {
        this.setFinalLendingRate(quoteData.quoteData);
        this.finalQuoteEvent.emit({ vehicle: this.staticVehicleInfo, statistics: quoteData.quoteData, active_rate: this.TASA_ACTIVA_SEGURO });
        this.uiService.loading(false, '');
      } else {
        this.uiService.error("Ocurrió un error al tratar de obtener la información, inténtelo más tarde, si el problema persiste, comuníquese con el administrador.");
      }
    } else {
      this.uiService.warning('Debe completar los datos antes de cotizar');
    }
  }

  async generateReverseQuote() {
    this.staticVehicleInfo = {
      marca: this.formCotizador.controls['marca'].value,
      modelo: this.formCotizador.controls['modelo'].value,
      anio: this.formCotizador.controls['anio'].value,
      precio: `$${this.formCotizador.controls['precioOriginal'].value}`,
      preioApex: this.formCotizador.controls['precioApex'].value,
      seguro: this.formCotizador.controls['primaOriginal'].value,
      seguroTotal: this.formCotizador.controls['primaTotal'].value
    };

    if (this.formReverseQuote.valid) {
      if (!(this.formReverseQuote.controls['pago_inicial'].value > this.formCotizador.controls['precioOriginal'].value)) {
        this.uiService.loading(true, 'Generando Cotización');
        let quoteParameters = {
          original_loan: this.formCotizador.controls['precioOriginal'].value,
          loan: this.formCotizador.controls['precioApex'].value,
          original_prima: this.formCotizador.controls['primaOriginal'].value,
          prima: this.formCotizador.controls['primaTotal'].value,
          pago_inicial: this.formReverseQuote.controls['pago_inicial'].value
        };

        let quoteReverseData: any = await this.quoterService.generateReverseQuote(quoteParameters);
        if (quoteReverseData?.ok) {
          this.setFinalLendingRate(quoteReverseData.quoteData);
          this.finalQuoteEvent.emit({ vehicle: this.staticVehicleInfo, statistics: quoteReverseData.quoteData, active_rate: this.TASA_ACTIVA_SEGURO });
          this.uiService.loading(false, '');
        } else {
          this.uiService.error("Ocurrió un error al tratar de obtener la información, inténtelo más tarde, si el problema persiste, comuníquese con el administrador.");
        }
      } else {
        this.uiService.warning('El pago inicial no puede ser mayor al valor total del vehiculo');
      }

    } else {
      this.uiService.warning('Debe añadir un monto para cotizar');
    }
  }

  async setConstants() {
    let allConstantsResponse: any = await this.quoterService.getAllConstants();
    if (allConstantsResponse?.ok) {
      this.ivaC = this.getConstantValue(allConstantsResponse.data, 'IVA');
      this.setGeneralConstants(allConstantsResponse.data);
      this.setTasaActivaTable(allConstantsResponse.data);
      this.setValorResidualTable(allConstantsResponse.data);
    } else {
      this.uiService.error("Ocuttión error al tratar de obtener la información, inténtelo más tarde.");
    }
  }

  setTasaActivaTable(data: constantI[]) {
    let constantsData = data.filter(function (c: constantI) { return c.name.includes("Tasa_Activa_Renta"); });
    this.TASA_ACTIVA_RENTA = constantsData.map((constantTAR: constantI) => {
      let constantName = constantTAR.name.split('_').pop();
      return { id: constantTAR.id, name: constantName, value: Math.round((constantTAR.value) * 10000) / 100 }
    });
    this.dataSourceTA = new MatTableDataSource(this.TASA_ACTIVA_RENTA);
    this.cdr.detectChanges();
  }

  setValorResidualTable(data: constantI[]) {
    let constantsData = data.filter(function (c: constantI) { return c.name.includes("Valor_Residual"); });
    this.VALOR_RESIDUAL = constantsData.map((constantTAR: constantI) => {
      let constantName = constantTAR.name.split('_').pop();
      return { id: constantTAR.id, name: constantName, value: Math.round((constantTAR.value) * 10000) / 100 }
    });
    this.dataSourceVR = new MatTableDataSource(this.VALOR_RESIDUAL);
    this.cdr.detectChanges();
  }


  setFinalLendingRate(data: any) {
    this.TASA_ACTIVA_SEGURO = data.map((quoteTerm: any) => {
      return {
        meses: quoteTerm.cotizacion_final.plazo,
        renta: Math.round((quoteTerm.tasa_renta) * 10000) / 100,
        irr: Math.round((quoteTerm.tir) * 10000) / 100,
        tasaActivaSeguro: Math.round((quoteTerm.tasa_activa) * 10000) / 100,
      }
    });
    this.dataSourceTAS = new MatTableDataSource(this.TASA_ACTIVA_SEGURO);
    this.cdr.detectChanges();
  }

  setGeneralConstants(data: constantI[]) {
    const quoterConstants = {
      iva: `${this.getConstantValue(data, 'IVA') * 100}%`,
      irrminimo: `${Math.round((this.getConstantValue(data, 'IRR') * 100) * 100) / 100}%`,
      udi: `${this.getConstantValue(data, 'UDI') * 100}%`,
      comision: `${this.getConstantValue(data, 'Comision') * 100}%`
    }
    this.formCotizador.patchValue(quoterConstants);
  }

  getConstantValue(data: constantI[], key: string) {
    let selectedConstant = data.filter(function (c) { return c.name === key; })[0];
    return selectedConstant.value;
  }

  roundTwoDecimals(value: number) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

}
