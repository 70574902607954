import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { DefaultResponse } from '@data/iterfaces/api/default-response';
import { UserInterface } from '@data/iterfaces/api/user';
import { UserConfiguration } from '@data/iterfaces/api/user-configuration.interface';
import { UserOneInterface } from '@data/iterfaces/api/user-one.interface';
import { UserSelectedInterface } from '@data/iterfaces/api/userSelected.interface';
import { UiService } from '@shared/services/ui.service';
import { environment } from 'environments/environment';
import { Subject, catchError, firstValueFrom, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _refresh$ = new Subject<void>();
  constructor(
    private http: HttpClient,
    private authService: AngularFireAuth,
    private uiService: UiService
  ) { }
  private url: string = environment.url;
  get refresh$() {
    return this._refresh$;
  }
  async getOne() {
    const endPoint = `${this.url}/admin/user/getOne`;
    let user = await firstValueFrom(this.authService.user);
    return new Promise(async (resolve) => {
      if (!user?.uid) return resolve(false)
      let userResponse: any = await firstValueFrom(this.http.post(`${endPoint}`, { 'uid': user?.uid }));
      userResponse.ok != true ? resolve({ ok: false }) : resolve({ ok: userResponse.ok, user: userResponse.user });
    });
  }

  async getOneInformation(userId: any) {
    return new Promise<UserOneInterface>(async (resolve) => {
      try {
        setTimeout(() => {
          const endPoint = `${this.url}/admin/user/get-one`;
          this.http.post<UserOneInterface>(`${endPoint}`, { userId })
            .pipe(
              catchError(error => {
                return of(error);
              }))
            .subscribe(response => {
              return resolve(response as UserOneInterface);
            });
        }, 180);
      } catch (error) {
        return resolve(error as UserOneInterface);
      }
    });
  }

  async getAll(withTrashed: any) {
    return new Promise<UserInterface>(async (resolve) => {
      try {
        setTimeout(() => {
          const endPoint = `${this.url}/admin/user/get-all/${withTrashed}`;
          this.http.get<UserInterface>(`${endPoint}`)
            .pipe(
              catchError(error => {
                return of(null);
              }))
            .subscribe(response => {
              return resolve(response as UserInterface);
            });
        }, 180);
      } catch (error) {
        return resolve(error as UserInterface);
      }
    });
  }

  async update(data: object) {
    this.uiService.loading(true, 'Actualizando');
    try {
      const endPoint = `${this.url}/admin/user/update`;
      const response = await firstValueFrom(
        this.http.post<DefaultResponse>(`${endPoint}`, data).pipe(tap(() => this._refresh$.next()))
      );
      this.uiService.loading(false, '');
      return response;
    } catch (error) {
      this.uiService.loading(false, '');
      this.uiService.error("Ocurrió un error, por favor verifique nuevamente.");
      throw error;
    }
  }

  async deactivate(userId: number) {
    this.uiService.loading(true, 'Actualizando');
    try {
      const endPoint = `${this.url}/admin/user/deactivate`;
      const response = await firstValueFrom(
        this.http.post<DefaultResponse>(`${endPoint}`, { userId }).pipe(tap(() => this._refresh$.next()))
      );
      this.uiService.loading(false, '');
      return response;
    } catch (error) {
      this.uiService.loading(false, '');
      this.uiService.error("Ocurrió un error, por favor verifique nuevamente.");
      throw error;
    }
  }

  async activate(userId: number) {
    this.uiService.loading(true, 'Actualizando');
    try {
      const endPoint = `${this.url}/admin/user/restore`;
      const response = await firstValueFrom(
        this.http.post<DefaultResponse>(`${endPoint}`, { userId }).pipe(tap(() => this._refresh$.next()))
      );
      this.uiService.loading(false, '');
      return response;
    } catch (error) {
      this.uiService.loading(false, '');
      this.uiService.error("Ocurrió un error, por favor verifique nuevamente.");
      throw error;
    }
  }

  async userConfiguration(userId: number) {
    return new Promise<UserConfiguration>(async (resolve) => {
      try {

        const endPoint = `${this.url}/admin/user/get-user-configurations`;
        this.http.post<UserConfiguration>(`${endPoint}`, { userId })
          .pipe(
            catchError(error => {
              return of(error as UserConfiguration);
            }))
          .subscribe(response => {
            if (!response.ok) return this.uiService.error("Ocurrió un error, al obtener los datos.")
            return resolve(response as UserConfiguration);
          });
      } catch (error) {
        return resolve(error as UserConfiguration);
      }
    });
  }

  async getAllUsersDeadlines(type: string) {
    return new Promise<UserSelectedInterface>(async (resolve) => {
      try {
        setTimeout(() => {
          const endPoint = `${this.url}/admin/user/get-all-users-deadlines/${type}`;
          this.http.get<UserSelectedInterface>(`${endPoint}`)
            .pipe(
              catchError(error => {
                return of(error as UserSelectedInterface);
              }))
            .subscribe(response => {
              return resolve(response as UserSelectedInterface);
            });
        }, 180);
      } catch (error) {
        return resolve(error as UserSelectedInterface);
      }
    });
  }

}