import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as fromComponents from './components';
import { UserSessionBtnComponent } from './components/user-session-btn/user-session-btn.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table'
import { QuoterReportComponent } from './components/quoter-report/quoter-report.component';
import { FinalReportComponent } from './components/final-report/final-report.component';
import { QuoterdosComponent } from './components/quoterdos/quoterdos.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { QuoterfinallyComponent } from './components/quoterfinally/quoterfinally.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { HistoricalTableComponent } from './components/historical-table/historical-table.component';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatCardModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatMenuModule,
    MatButtonModule
  ],
  declarations: [...fromComponents.components,
    UserSessionBtnComponent,
    QuoterReportComponent,
    FinalReportComponent,
    QuoterdosComponent,
    QuoterfinallyComponent,
    HistoricalTableComponent,
  ]
  ,
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UserSessionBtnComponent,
    QuoterReportComponent,
    FinalReportComponent,
    QuoterdosComponent,
    QuoterfinallyComponent,
    ...fromComponents.components
  ]
})
export class SharedModule { }
