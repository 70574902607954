import { async } from '@firebase/util';
import { DefaultResponse } from './../../../iterfaces/api/default-response';
import { UiService } from '@shared/services/ui.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { allConstantsResponseI, quoterResponseI, requestQuoterI } from '@data/iterfaces/api/quoter';
import { QuoterResponse } from '@data/iterfaces/api/quoterpy';
import { environment } from 'environments/environment';
import { firstValueFrom, Subject, tap } from 'rxjs';
import { VarsResponse } from '@data/iterfaces/api/vars';
import { VarsConfigurationResponse } from '@data/iterfaces/api/vars-configuration';

@Injectable({
  providedIn: 'root'
})
export class QuoterService {
  private _refresh$ = new Subject<void>();

  constructor(private http: HttpClient, private uiService: UiService) { }

  get refresh$() {
    return this._refresh$;
  }

  private url: string = environment.url;

  async generateQuote(data: requestQuoterI) {
    const endPoint = `${this.url}/actions/generateQuote`;
    return new Promise(async (resolve) => {
      try {
        this.http.post<quoterResponseI>(`${endPoint}`, { parameters: data }).subscribe(quoteResponse => {
          quoteResponse.ok != true ? resolve({ ok: false }) : resolve({ ok: quoteResponse.ok, quoteData: quoteResponse.data });
        },
          err => {
            console.log(err);
            resolve({ ok: false })
          });
      } catch (error) {
        console.log(error);
        resolve({ ok: false });
      }
    });
  }

  async generateQuotePy(data: object) {
    this.uiService.loading(true, 'Generando Cotización');
    return new Promise(async (resolve) => {
      try {
        setTimeout(() => {
          const endPoint = `${this.url}/test/generate-quoter`;
          this.http.post<QuoterResponse>(`${endPoint}`, data).subscribe(response => {
            return resolve(response);
          },
            error => {
              console.error(error)
              return resolve(error);
            });
          this.uiService.loading(false, '');
        }, 300);
      } catch (error) {
        this.uiService.loading(false, '');
        this.uiService.error("Ocurrió un error, por favor intente mas tarde.");
        return resolve(error);
      }
    });
  }

  async getAllVars(userId:any) {
    this.uiService.loading(true, 'Obteniendo datos');
    return new Promise(async (resolve) => {
      try {
        setTimeout(() => {
          const endPoint = `${this.url}/test/vars-all/${userId}`;
          this.http.get<VarsResponse>(`${endPoint}`).subscribe(response => {
            return resolve(response);
          },
            error => {
              console.error(error)
              return resolve(error);
            });
          this.uiService.loading(false, '');
        }, 300);
      } catch (error) {
        this.uiService.loading(false, '');
        this.uiService.error("Ocurrió un error, por favor verifique nuevamente.");
        return resolve(error);
      }
    });
  }

  async getAllVarsConfiguration(withTrashed: any) {
    return new Promise(async (resolve) => {
      try {
        setTimeout(() => {
          const endPoint = `${this.url}/test/vars-configuration/${withTrashed}`;
          this.http.get<VarsConfigurationResponse>(`${endPoint}`).subscribe(response => {
            return resolve(response);
          },
            error => {
              console.error(error)
              return resolve(error);
            });
        }, 180);
      } catch (error) {
        this.uiService.error("Ocurrió un error, por favor verifique nuevamente.");
        return resolve(error);
      }
    });
  }

  async deadlineDeactivate(id: number) {
    this.uiService.loading(true, 'Actualizando');
    return new Promise(async (resolve) => {
      try {
        setTimeout(async () => {
          const endPoint = `${this.url}/test/deadline-deactivate`;
          const response = await firstValueFrom(
            this.http.post<DefaultResponse>(`${endPoint}`, { id }).pipe(tap(() => this._refresh$.next()))
          );
          this.uiService.loading(false, '');
          return resolve(response);
        }, 600);
      } catch (error) {
        this.uiService.loading(false, '');
        this.uiService.error("Ocurrió un error, por favor verifique nuevamente.");
        return resolve(error);
      }
    });
  }

  async deadlineActivate(id: number) {
    this.uiService.loading(true, 'Actualizando');
    return new Promise(async (resolve) => {
      try {
        setTimeout(async () => {
          const endPoint = `${this.url}/test/deadline-activate`;
          const response = await firstValueFrom(
            this.http.post<DefaultResponse>(`${endPoint}`, { id }).pipe(tap(() => this._refresh$.next()))
          )
          this.uiService.loading(false, '');
          return resolve(response);
        }, 600);
      } catch (error) {
        this.uiService.loading(false, '');
        this.uiService.error("Ocurrió un error, por favor verifique nuevamente.");
        return resolve(error);
      }
    });
  }

  async deadlineUpdate(data: object) {
    this.uiService.loading(true, 'Actualizando');
    return new Promise(async (resolve) => {
      try {
        setTimeout(async () => {
          const endPoint = `${this.url}/test/deadline-update`;
          const response = await firstValueFrom(
            this.http.put<DefaultResponse>(`${endPoint}`, data).pipe(tap(() => this._refresh$.next()))
          );
          this.uiService.loading(false, '');
          return resolve(response);
        }, 600);
      } catch (error) {
        this.uiService.loading(false, '');
        this.uiService.error("Ocurrió un error, por favor verifique nuevamente.");
        return resolve(error);
      }
    });
  }

  async updateVars(data: object) {
    this.uiService.loading(true, 'Actualizando');
    return new Promise(async (resolve) => {
      try {
        setTimeout(async () => {
          const endPoint = `${this.url}/test/variables-update`;
          const response = await firstValueFrom(
            this.http.put<DefaultResponse>(`${endPoint}`, data).pipe(tap(() => this._refresh$.next()))
          );
          this.uiService.loading(false, '');
          return resolve(response);
        }, 600);
      } catch (error) {
        this.uiService.loading(false, '');
        this.uiService.error("Ocurrió un error, por favor verifique nuevamente.");
        return resolve(error);
      }
    });
  }

  async generateReverseQuote(data: requestQuoterI) {
    const endPoint = `${this.url}/actions/generateReverseQuote`;
    return new Promise(async (resolve) => {
      try {
        this.http.post<quoterResponseI>(`${endPoint}`, { parameters: data }).subscribe(quoteResponse => {
          quoteResponse.ok != true ? resolve({ ok: false }) : resolve({ ok: quoteResponse.ok, quoteData: quoteResponse.data });
        },
          err => {
            console.log(err);
            resolve({ ok: false })
          });
      } catch (error) {
        console.log(error);
        resolve({ ok: false });
      }
    });
  }

  async getAllConstants() {
    const endPoint = `${this.url}/actions/getAllConstants`;
    return new Promise(async (resolve) => {
      let quoteResponse = await firstValueFrom(this.http.get<allConstantsResponseI>(`${endPoint}`));
      quoteResponse.ok != true ? resolve({ ok: false }) : resolve(quoteResponse);
    });
  }

  async updateConstants(control: AbstractControl) {
    const endPoint = `${this.url}/actions/updateConstants`;
    return new Promise(async (resolve) => {
      try {
        const data = [
          {
            name: 'IVA',
            value: control.get('iva')?.value,
          },
          {
            name: 'IRR',
            value: control.get('irr')?.value,
          },
          {
            name: 'UDI',
            value: control.get('udi')?.value,
          },
          {
            name: 'Comision',
            value: control.get('comision')?.value,
          },
          {
            name: 'Tasa_Activa_Renta_24',
            value: control.get('tasa_activa_renta_24')?.value,
          },
          {
            name: 'Tasa_Activa_Renta_36',
            value: control.get('tasa_activa_renta_36')?.value,
          },
          {
            name: 'Tasa_Activa_Renta_48',
            value: control.get('tasa_activa_renta_48')?.value,
          },
          {
            name: 'Valor_Residual_24',
            value: control.get('valor_residual_24')?.value,
          },
          {
            name: 'Valor_Residual_36',
            value: control.get('valor_residual_36')?.value,
          },
          {
            name: 'Valor_Residual_48',
            value: control.get('valor_residual_48')?.value,
          },
          {
            name: 'Adicional_Renta_24',
            value: control.get('adicional_renta_24')?.value,
          },
          {
            name: 'Adicional_Renta_36',
            value: control.get('adicional_renta_36')?.value,
          },
          {
            name: 'Adicional_Renta_48',
            value: control.get('adicional_renta_48')?.value,
          },
          {
            name: "Saldo_insoluto_inicial",
            value: control.get('saldo_insoluto_inicial')?.value
          },
          {
            name: "Ratificaciones",
            value: control.get('ratificaciones')?.value
          },
          {
            name: "Gastos_administrativos",
            value: control.get('gastos_administrativos')?.value
          },
          {
            name: "Costo_gps_24",
            value: control.get('costo_gps_24')?.value
          },
          {
            name: "Costo_gps_36",
            value: control.get('costo_gps_36')?.value
          },
          {
            name: "Costo_gps_48",
            value: control.get('costo_gps_48')?.value
          }
        ];
        let quoteResponse = await firstValueFrom(this.http.post<allConstantsResponseI>(`${endPoint}`, { constants: data }));
        quoteResponse.ok != true ? resolve({ ok: false }) : resolve(quoteResponse);
      } catch (error) {
        resolve({ ok: false })
      }
    });
  }
}
