<section *ngIf="isLoading">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true"
            (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="text-center">
                <mat-panel-title class="text-center" *ngIf="!panelOpenState">
                    Cotizaciones historicas
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row gy-2 d-flex justify-content-center">
                <div class="col-12 d-flex justify-content-center text-center"
                    [ngClass]="canShow  ? 'col-lg-6' : 'col-lg-10'">
                    <mat-card class="w-100">
                        <mat-card-content class="scroll-x scroll--simple">
                            <mat-card-title>Cotizaciones historicas</mat-card-title>
                            <div class="container-fluid">
                                <div class="row variables-block justify-content-center">
                                    <div class="col-lg-6 col-12 d-flex justify-content-lg-end justify-content-sm-end">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Buscar cotización</mat-label>
                                            <input matInput (keyup)="filterTable($event)" placeholder="Cotizaciones"
                                                #input>
                                            <mat-icon matSuffix>search</mat-icon>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <mat-form-field appearance="fill">
                                            <mat-label>Seleccione un usuario</mat-label>
                                            <mat-select [(value)]="selected" class="col"
                                                (selectionChange)="initializeData()">
                                                <mat-option *ngFor="let item of users" value="{{item.id}}">
                                                    <span
                                                        [innerHTML]="+userId == item.id ? '<strong>' + item.name + '</strong>' : item.name">
                                                    </span>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <table mat-table [dataSource]="dataSource" class="table-data" matSort>
                                <ng-container *ngFor="let column of columns" [matColumnDef]="column.value">
                                    <th mat-header-cell class="text-center text-header" *matHeaderCellDef
                                        mat-sort-header>
                                        {{column.title}}</th>
                                    <td mat-cell class="td-title" *matCellDef="let element">
                                        <ng-container>
                                            <span>
                                                {{ element[column.value] }}
                                            </span>
                                        </ng-container>
                                        <ng-container *ngIf="column.value == 'actions'">
                                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                                aria-label="Example icon-button with a menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item (click)="generateQuoter(element)">
                                                    <mat-icon>attach_money</mat-icon>
                                                    <span>Cotizar</span>
                                                </button>
                                            </mat-menu>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="getColumns()"></tr>
                                <tr mat-row *matRowDef="let row; columns: getColumns();"></tr>
                            </table>
                            <mat-paginator #paginator [pageSizeOptions]="[5,10,25,50,100]" [pageSize]="5"
                                showFirstLastButtons aria-label="Select page of data" (page)="pageChanged($event)">
                            </mat-paginator>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
<section *ngIf="!isLoading">
    <div style="margin-top: 4rem;"> Error al obtener la información.</div>
</section>