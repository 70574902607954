import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor() { }

  error(title: String) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: 'white',
      background: 'rgba(252,87,83,0.8)',
      customClass: {
      },
      buttonsStyling: true,
      showConfirmButton: true,
      timer: 3500,
      timerProgressBar: true,
    })
    Toast.fire({
      icon: 'error',
      title: title,
      color: 'black',
      confirmButtonText: '<span style="color: rgb(0, 0, 0); font-weight: bold;">Aceptar<span/>',
      confirmButtonColor: 'rgba(255, 255, 255,0.4 )',
    })
  }

  /*  success(title: String) {
     const Toast = Swal.mixin({
       toast: true,
       position: 'top-end',
       showConfirmButton: true,
       background: '#aee7f8',
       customClass: {
         title: 'success-text',
         confirmButton: 'btn-success-ok'
       }
     });

     Toast.fire({
       confirmButtonText: 'Aceptar',
       title: title
     });
   } */

  success(title: String) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: 'white',
      background: 'rgba(51,199,72,0.8)',
      customClass: {
        popup: 'colored-toast',
        title: 'success-text',
        confirmButton: 'btn-success-ok'
      },
      buttonsStyling: true,
      showConfirmButton: true,
      timer: 3500,
      timerProgressBar: true
    })
    Toast.fire({
      icon: 'success',
      title: title,
      color: 'black',
      confirmButtonText: '<span style="color: rgb(0, 0, 0); font-weight: bold;">Aceptar<span/>',
      confirmButtonColor: 'rgba(255, 255, 255,0.4 )',
    })
  }

  warning(title: String) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      background: 'rgba(253,188,64,0.8)',
      customClass: {
        title: 'warning-text',
        confirmButton: 'btn-warning-ok'
      },
      buttonsStyling: true,
      showConfirmButton: true,
      timer: 3500,
      timerProgressBar: true
    });

    Toast.fire({
      icon: 'warning',
      title: title,
      color: 'black',
      confirmButtonText: '<span style="color: rgb(0, 0, 0); font-weight: bold;">Aceptar<span/>',
      confirmButtonColor: 'rgba(255, 255, 255,0.4 )',
    })
  }

  loading(isLoading: boolean, title: String) {
    if (isLoading) {
      Swal.fire({
        heightAuto: false,
        title: title,
        html: 'Cargando...',
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        didClose: () => {
          Swal.hideLoading();
        }
      });
    } else {
      Swal.close();
    }
  }

}
