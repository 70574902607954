<div class="container-shadow text-center">
    <div *ngIf="loading">
        <div class="d-flex justify-content-center aligns-items-center">
            <img class="img-fluid img-report" src="../../../../assets/images/logo-color.png" alt="">
        </div>
        Error al obtener la información
    </div>
    <div class="d-flex justify-content-center aligns-items-center">
        <img class="img-fluid img-report" src="../../../../assets/images/logo-color.png" alt="">
    </div>
    <div class="form-group row d-flex justify-content-center">
        <div class="text-center align-items-center row">
            <div *ngIf="userRolId === 1" class="col-md col-sm-12">
                <strong *ngIf="!initial && !historical">Seleccione tipo de cotización:</strong>
                <mat-radio-group [(ngModel)]="selectedOption" (change)="selectedRadio()">
                    <mat-radio-button *ngFor="let radioButton of radioButtons" [value]="radioButton.value">
                        {{ radioButton.description }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="userRolId === 1 && initial" class="col-md col-sm-12 user-selected">
                <mat-form-field appearance="fill">
                    <mat-label>Seleccione un usuario</mat-label>
                    <mat-select [(value)]="selected" class="col" (selectionChange)="setData()">
                        <mat-option *ngFor="let item of users" [value]="item.id">
                            <span [innerHTML]="userId == item.id ? '<strong>' + item.name + '</strong>' : item.name">
                            </span>
                            - {{ item.active_rate }}
                        </mat-option>
                    </mat-select>

                </mat-form-field>
            </div>
            <div *ngIf="initial && canSelectCommissions" class="col-md col-sm-12 user-selected">
                <mat-form-field appearance="fill">
                    <mat-label>Seleccione % COM apertura</mat-label>
                    <mat-select [(value)]="selectedCommission" class="col" (selectionChange)="changeCommission()">
                        <mat-option *ngFor="let item of commissions" [value]="item">
                            {{ item.description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="isDataFinally" class="col-md col-sm-12">
                <button class="btn quoter-btn" type="button" (click)="resetData()">
                    Restablecer datos
                </button>
            </div>
        </div>

    </div>

    <div *ngIf="historical">
        <app-historical-table (finalQuoteEvent)="receiveQuoterData($event)"></app-historical-table>
    </div>

    <div *ngIf="initial || isDataHistorical">
        <div class=" form-group row d-flex justify-content-center">
            <h3 class="text-center title-report">CONSTANTES </h3>
            <div class="col-10 row variables-block justify-content-center">
                <hr>
                <p class="col-lg-3 col-4 details">
                    Iva:
                    <span class="span_bold">&nbsp;&nbsp;{{iva}}</span>
                </p>
                <p class="col-lg-3 col-4 details" *ngIf="userRolId == 1">
                    IRR Mínimo:
                    <span class="span_bold">&nbsp;&nbsp;{{tir}}</span>
                </p>
                <p class="col-lg-3 col-4 details" *ngIf="userRolId == 1">
                    UDI:
                    <span class="span_bold">&nbsp;&nbsp;{{udi}}</span>
                </p>
                <p class="col-lg-3 col-4 details">
                    Comisión:
                    <span class="span_bold">&nbsp;&nbsp;{{comission}}</span>
                </p>
            </div>
            <div class="col-lg-6 col-md-6 col-10" *ngIf="userRolId == 1">
                <table mat-table [dataSource]="activeRateDosData" class="table-tasa-activa">
                    <ng-container matColumnDef="meses">
                        <th mat-header-cell *matHeaderCellDef class="text-center"> Meses </th>
                        <td mat-cell *matCellDef="let element"> {{element.month}} </td>
                    </ng-container>
                    <ng-container matColumnDef="tasaActiva">
                        <th mat-header-cell *matHeaderCellDef class="text-center"> Tasa Activa Renta</th>
                        <td mat-cell *matCellDef="let element"> {{element.active_rate}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsTasas"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsTasas;"></tr>
                </table>
            </div>
            <div class="col-lg-6 col-md-6 col-10">
                <table mat-table [dataSource]="dataSourceVR" class="table-tasa-activa">
                    <ng-container matColumnDef="meses">
                        <th mat-header-cell *matHeaderCellDef class="text-center"> Meses </th>
                        <td mat-cell *matCellDef="let element"> {{element.month}} </td>
                    </ng-container>
                    <ng-container matColumnDef="valorResidual">
                        <th mat-header-cell *matHeaderCellDef class="text-center"> Valor Residual </th>
                        <td mat-cell *matCellDef="let element"> {{element.residual_value}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsResidual"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsResidual;"></tr>
                </table>
            </div>
        </div>

    </div>
    <div *ngIf="initial">
        <div class=" form-group row d-flex justify-content-center">
            <h3 class="text-center title-report">VARIABLES</h3>
            <div class="col-12 d-flex justify-content-center">
                <h4 class="text-start subtitle-report">¿Pago inicial incluye primera renta? &nbsp;&nbsp;
                    <input type="checkbox" [(ngModel)]="checked" [ngModelOptions]="{standalone: true}"
                        name="has_payment" id="has_payment">
                </h4>
            </div>
            <form class="text-center" *ngIf="isVarsData" (ngSubmit)="generateQuote()" [formGroup]="formCotizador">
                <div class="form-group row d-flex justify-content-center">
                    <h4 class="text-start subtitle-report">Detalles del Vehículo:</h4>
                    <div class="col-lg-4 col-md-4 col-12">
                        <mat-form-field hintLabel="Máximo 80 carácteres" appearance="fill" class="variable-input">
                            <mat-label>Marca</mat-label>
                            <input matInput #marcaInput maxlength="80" formControlName="marca" type="text"
                                placeholder="Ingrése la marca">
                            <mat-hint align="end">{{marcaInput.value.length || 0}}/80</mat-hint>
                            <mat-error *ngIf="formCotizador.controls['marca'].hasError('required')">
                                El marca es requerida
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <mat-form-field hintLabel="Máximo 80 carácteres" appearance="fill" class="variable-input">
                            <mat-label>Modelo</mat-label>
                            <input matInput #modeloInput maxlength="80" type="text" formControlName="modelo"
                                placeholder="Ingrése el modelo">
                            <mat-hint align="end">{{modeloInput.value.length || 0}}/80</mat-hint>
                            <mat-error *ngIf="formCotizador.controls['modelo'].hasError('required')">
                                El modelo es requerido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <mat-form-field hintLabel="Longitud de 4 carácteres" appearance="fill" class="variable-input">
                            <mat-label>Año</mat-label>
                            <input matInput #anioInput formControlName="year" value="0" [min]="0" [max]="9999"
                                [minLength]="4" [maxLength]="4" type="number" placeholder="Ingrése el año">
                            <mat-hint align="end">{{anioInput.value.length || 0}}/4</mat-hint>
                            <mat-error *ngIf="formCotizador.controls['year'].hasError('required')">
                                El año es requerido
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <div class="form-group row d-flex justify-content-center mt-4">
                    <div class="col-lg-4 col-md-4 col-12">
                        <mat-form-field appearance="fill" floatLabel="always" class="variable-input">
                            <mat-label>Precio Original</mat-label>
                            <input matInput #automobileInput type="number" value="0" formControlName="automobile"
                                [min]="0" [minLength]="1" [maxLength]="20" [max]="9999999999" step="0.01"
                                placeholder="0" (input)="vatApplied(automobileInput.value, 0)"
                                class="example-right-align" (keydown)="onKeyDown($event)">

                            <span matPrefix>$&nbsp;</span>
                            <mat-error *ngIf="formCotizador.controls['automobile'].hasError('required')">
                                El precio es requerido
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <mat-form-field appearance="fill" hintLabel="Se rellenará automáticamente" floatLabel="always"
                            class="variable-input">
                            <mat-label>Precio APEX (Sin IVA/1.16)</mat-label>
                            <input matInput type="number" value="0" [min]="0" class="example-right-align"
                                formControlName="apexPrice" placeholder="0" inputmode="numeric"
                                oninput="this.value = Math.abs(parseInt(this.value)) || 0" readonly>
                            <span matPrefix>$&nbsp;</span>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <mat-form-field appearance="fill" hintLabel="Se rellenará automáticamente" floatLabel="always"
                            class="variable-input">
                            <mat-label>Precio Público (Sin IVA/1.16)</mat-label>
                            <input matInput type="number" value="0" class="example-right-align"
                                formControlName="publicPrice" placeholder="0" inputmode="numeric"
                                oninput="this.value = Math.abs(parseInt(this.value)) || 0" readonly>
                            <span matPrefix>$&nbsp;</span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-center" style="margin-top: 2%;">
                    <h4 class="text-start subtitle-report">¿Incluye seguro? &nbsp;&nbsp;
                        <input type="checkbox" [(ngModel)]="checkedInsurance" [ngModelOptions]="{standalone: true}"
                            name="includes_insurance" id="includes_insurance">
                    </h4>
                </div>
                <div class="form-group row d-flex justify-content-center mt-4" *ngIf="checkedInsurance">
                    <div class="form-group row d-flex justify-content-center mt-4">
                        <h4 class="text-start subtitle-report">Seguro:</h4>
                        <div class="col-lg-4 col-md-4 col-12">
                            <mat-form-field hintLabel="Máximo 20 carácteres" appearance="fill" class="variable-input">
                                <mat-label>Compañía</mat-label>
                                <input matInput #companiaInput maxlength="40" type="text" formControlName="company"
                                    placeholder="Ingrése la compañía">
                                <mat-hint align="end">{{companiaInput.value.length || 0}}/40</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                            <mat-form-field hintLabel="Máximo 20 carácteres" appearance="fill" class="variable-input">
                                <mat-label>Prima</mat-label>
                                <input matInput #primaInput type="number" formControlName="insurance_value" [min]="0"
                                    [minLength]="1" [maxLength]="20" [max]="9999999999" step="0.01"
                                    placeholder="Ingrese la prima" (input)="vatApplied(primaInput.value, 1)"
                                    (keydown)="onKeyDown($event)">

                                <mat-error *ngIf="formCotizador.controls['insurance_value'].hasError('required')">
                                    El valor es requerido
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                            <mat-form-field hintLabel="Se rellenará automáticamente" appearance="fill"
                                class="variable-input">
                                <mat-label>Prima Total</mat-label>
                                <input matInput #primaTotalInput type="number" formControlName="fullPremium" [min]="0"
                                    [max]="99999999" placeholder="Ingrése el campo prima" readonly=""
                                    inputmode="numeric" oninput="this.value = Math.abs(parseInt(this.value)) || 0">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="form-group row d-flex justify-content-center mt-4">
                    <div class="form-group row d-flex justify-content-center mt-4">
                        <div class="text-center align-items-center">
                            <button class="btn quoter-btn" type="submit" [disabled]="formCotizador.invalid">
                                Cotizar
                            </button>
                        </div>
                        <div class="row">
                            <div class="col-0 col-lg-6">
                            </div>
                            <div class="col-12 col-lg-6">
                                <h4 class="text-end subtitle-report">¿Desea agregar un pago incial mayor?</h4>
                                <div class="col-12 d-flex justify-content-end">
                                    <mat-form-field appearance="fill" class="variable-input">
                                        <mat-label>Monto</mat-label>
                                        <input matInput #montoMayor type="number" formControlName="higher_down_payment"
                                            [min]="0" [max]="99999999" placeholder="Ingrése el monto"
                                            inputmode="numeric"
                                            oninput="this.value = Math.abs(parseInt(this.value)) || 0">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>

        </div>
    </div>
    <div class="form-group row d-flex justify-content-center mt-4" *ngIf="isDataFinally && userRolId === 1">
        <h3 class="text-center title-report">TASA ACTIVA</h3>
        <table mat-table mat-table [dataSource]="activeRateData" class="table-cotizacion">
            <ng-container matColumnDef="month">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Meses </th>
                <td mat-cell *matCellDef="let element"> {{element.month}} </td>
            </ng-container>
            <ng-container matColumnDef="irr">
                <th mat-header-cell *matHeaderCellDef class="text-center"> IRR </th>
                <td mat-cell *matCellDef="let element"> {{element.irr}}</td>
            </ng-container>
            <ng-container matColumnDef="activeRate">
                <th mat-header-cell *matHeaderCellDef class="text-center"> Tasa Activa Seguro</th>
                <td mat-cell *matCellDef="let element"> {{element.activeRate}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsActiveRate"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsActiveRate;"></tr>
        </table>
    </div>