import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninGuard } from '@core/guards/auth/signin.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@modules/auth/auth.module').then( m => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/pages/pages.module').then( m => m.PagesModule ),
    canLoad: [ SigninGuard ]
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
