import { VarsResponse } from './../../../data/iterfaces/api/vars';
import { QuoterResponse } from './../../../data/iterfaces/api/quoterpy';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuoterService } from '@data/services/api/protected/quoter.service';
import { UiService } from '@shared/services/ui.service';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'environments/environment';
import { UserService } from '@data/services/api/protected/user.service';
import { User, UserSelectedInterface } from '@data/iterfaces/api/userSelected.interface';
import { SettingsService } from '@data/services/settings/settings.service';
import { Commission, CommissionInterface } from '@data/iterfaces/api/commissions.interface';
import { CommissionService } from '@data/services/api/public/commissions/commissions.service';
@Component({
  selector: 'app-quoterdos',
  templateUrl: './quoterdos.component.html',
  styleUrls: ['./quoterdos.component.sass']
})
export class QuoterdosComponent implements OnInit {
  @Output() finalQuoteEvent = new EventEmitter<any>();
  public userRolId!: any;
  public userId!: any;
  public response!: QuoterResponse;
  public responseVars!: VarsResponse;
  public responseCommission!: CommissionInterface;
  public activeRateData: any;
  public activeRateDosData: any;
  public dataSourceVR: any;
  public isDataFinally!: boolean;
  public isVarsData!: boolean;
  public checked: boolean = true;
  public checkedInsurance: boolean = false;
  public loading!: boolean;
  public iva!: string;
  public tir!: string;
  public udi!: string;
  public comission!: string;
  public displayedColumnsActiveRate: string[] = ['month', 'irr', 'activeRate'];
  public displayedColumnsTasas: string[] = ['meses', 'tasaActiva'];
  public displayedColumnsResidual: string[] = ['meses', 'valorResidual'];
  public ivaCalculate: number = 1.16;
  public apexPrice!: string;
  public formValues!: Object;
  private faker: boolean = environment.faker;
  selectedOption!: string;
  initial: boolean = false;
  historical: boolean = false;
  isDataHistorical: boolean = false;
  canSelectCommissions: boolean = false;
  commissions!: Commission[];
  optionMap!: {
    [key: string]: { initial: boolean; historical: boolean };
  }
  public selected: any = 'none';
  public selectedCommission: any = 'none';
  userSelected!: UserSelectedInterface | null;
  users!: User[];
  radioButtons = [
    { value: 'initial', description: 'Cotizador Inicial' },
    { value: 'historical', description: 'Histórico' }
  ];

  settigns!: any;

  constructor(
    private formBuilder: FormBuilder,
    private quoterService: QuoterService,
    private uiService: UiService,
    private userService: UserService,
    private settingsService: SettingsService,
    private commissionService: CommissionService,
  ) { }

  formCotizador: FormGroup = this.formBuilder.group({
    marca: [null, [Validators.required]],
    modelo: [null, [Validators.required]],
    year: [null, [Validators.required]],
    company: [null],
    automobile: [null, [Validators.required]],
    has_payment: [null, [Validators.required]],
    insurance_value: [null, [Validators.required]],
    higher_down_payment: [null],
    apexPrice: [null],
    publicPrice: [null],
    fullPremium: [null],
  });

  async ngOnInit(): Promise<void> {
    this.loading = this.isDataFinally = false;
    this.userRolId = localStorage.getItem('rolId');
    this.userId = localStorage.getItem('user_id');
    this.userRolId = parseInt(this.userRolId);
    this.settigns = await this.settingsService.getSettings();
    this.canSelectCommissions = this.settigns.canSelectCommissions;
    if (this.userRolId === 2) {
      this.initial = true;
      this.setData();
    }
    if (this.canSelectCommissions && this.initial) { await this.getOpeningCommissions() }
  }

  async getOpeningCommissions() {
    this.responseCommission = await this.commissionService.getAll();
    this.commissions = this.responseCommission.commissions;
  }

  async generateQuote() {
    if (this.formCotizador.invalid) return this.uiService.warning("Todos los valores son requeridos.");
    this.validHigherDownPayment();
    const data = this.getQuoterData();
    const response: any = await this.quoterService.generateQuotePy(data);
    this.response = response
    if (!this.response.ok) return this.uiService.error("Ocurrió un error, por favor verifique nuevamente.");
    this.setActiveRateData(this.response.quoter.activeRate);
    this.isDataFinally = this.response.ok;
    this.finalQuoteEvent.emit(this.response);
    this.uiService.success('Cotización generada.');
  }

  changeCommission() {
    this.comission = this.selectedCommission.description;
  }

  async receiveQuoterData($event: any) {
    this.finalQuoteEvent.emit($event.data);
    this.isDataFinally = $event.data.ok;
    this.isDataHistorical = $event.data.ok;
    this.selected = $event.user;
    await this.getVars();
    this.setActiveRateData($event.data.quoter.activeRate);
  }

  validHigherDownPayment() {
    let higher_down_payment: number = this.formCotizador.controls['higher_down_payment'].value;
    if (higher_down_payment === null || higher_down_payment < 0 || higher_down_payment === undefined) {
      this.formCotizador.get('higher_down_payment')?.setValue(0);
    }
  }

  getQuoterData() {
    this.checked ? this.formCotizador.get('has_payment')?.setValue(1) : this.formCotizador.get('has_payment')?.setValue(0)
    if (!this.checkedInsurance) this.formCotizador.get('insurance_value')?.setValue(0);
    const data = this.formCotizador.value;
    delete data.publicPrice;
    delete data.fullPremium;
    delete data.apexPrice;
    data.changeUser = this.selected != 'none' ? +this.selected : 0;
    data.commission = this.selectedCommission !== 'none' ? this.selectedCommission.commission : null;
    return data;
  }

  setActiveRateData = (data: any): any => (this.activeRateData = new MatTableDataSource(data));

  async setData() {
    if (this.faker) {
      this.formCotizador.setValue({
        marca: 'Ford',
        modelo: 'Modelo ford',
        year: 2022,
        company: '',
        automobile: 2400000,
        has_payment: 0,
        insurance_value: 0,
        higher_down_payment: 600000,
        apexPrice: null,
        publicPrice: null,
        fullPremium: null,
      });
    } else {
      this.formCotizador.patchValue({
        insurance_value: 0,
        has_payment: 0,
        higher_down_payment: 0,
      });
    }
    await this.getVars();
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key.toLowerCase() === "e") {
      event.preventDefault();
    }
  }

  vatApplied(filterValue: any, variablesNum: number) {
    if (variablesNum === 0) {
      this.apexPrice = this.setOperations(filterValue);
      this.formValues = { apexPrice: this.apexPrice, publicPrice: this.apexPrice };
    } else if (variablesNum === 1) {
      this.formValues = { fullPremium: this.setOperations(filterValue) };
    }
    this.formCotizador.patchValue(this.formValues);
  }

  setOperations(data: any) {
    const numericValue = parseFloat(data) || 0;
    return (numericValue / this.ivaCalculate).toFixed(2);
  }

  resetData(): void {
    this.selectedOption = '';
    window.location.reload()
  }

  async selectedRadio() {

    this.optionMap = {
      initial: { initial: true, historical: false },
      historical: { initial: false, historical: true },
    };

    const selectedOptionKeys = Object.keys(this.optionMap);

    if (selectedOptionKeys.includes(this.selectedOption)) {
      const { initial, historical } = this.optionMap[this.selectedOption];
      this.initial = initial;
      this.historical = historical;
      if (this.selected != 'none') { this.selected = 'none' }
      this.finalQuoteEvent.emit({ ok: false });
      this.isVarsData = false;
      this.isDataFinally = false;
      this.isDataHistorical = false;
      this.formCotizador.reset();
      if (initial) {
        this.userSelected = await this.userService.getAllUsersDeadlines('activeRate');
        await this.getOpeningCommissions()
        this.users = this.userSelected.users;
        this.setData();
      }
    } else {
      this.initial = false;
      this.historical = false;
      this.selected = 'none';
    }
  }


  async getVars() {
    const response: any = await this.quoterService.getAllVars(this.selected != 'none' ? +this.selected : null);
    this.responseVars = response;
    if (!this.responseVars.ok) {
      this.loading = true;
      return this.uiService.error("Ocurrió un error, por favor verifique nuevamente.");
    }
    this.isVarsData = this.responseVars.ok;
    this.iva = this.responseVars.vars.iva;
    this.tir = this.responseVars.vars.tir_min;
    this.udi = this.responseVars.vars.udi;
    this.comission = this.responseVars.vars.comission;
    let vars: any = this.responseVars.vars.vars_deadLines;
    this.activeRateDosData = new MatTableDataSource(vars);
    this.dataSourceVR = new MatTableDataSource(vars);
  }
}