import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '@data/services/auth/auth.service';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SigninGuard implements CanLoad {

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private authService: AuthService
  ) {}

  async canLoad(): Promise<boolean> {
    let user = await firstValueFrom(this.auth.user);
    //let token = await this.authService.validToken();    
    
    if(user /*&& token*/) {
      return true;
    } else {
      localStorage.clear();
      await this.auth.signOut();
      this.router.navigateByUrl('/auth/login');
      return false;
    }    
  } 
}
