<div class="container-shadow" *ngIf="VARIABLES_REPORTE != undefined">
  <div id="quoteDataDiv" #quoteDataDiv>
    <div class="d-flex justify-content-center aligns-items-center">
      <img class="img-fluid img-report" src="../../../../assets/images/logo-color.png" alt="">
    </div> 
    <h3 class="text-center title-report">COTIZACIÓN</h3>
    
    <p class="text-center mt-4">Por medio de la presente a nombre de Arrendadora Apex le hacemos llegar su cotización de arrendamiento para el vehículo:</p>
    <form class="form-group row d-flex justify-content-center mt-4" [formGroup]="formVehicle"> 
      <h4 class="text-start subtitle-report">Detalles del Vehículo:</h4>
      <div class="col-lg-4 col-md-4 col-12 mt-2 d-flex justify-content-center">
          <mat-form-field appearance="legacy" hintLabel="MARCA" class="variable-input">
            <mat-label>Marca</mat-label>
            <input matInput formControlName="marca" type="text" placeholder="MARCA" readonly>
          </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 col-12 mt-2 d-flex justify-content-center">
          <mat-form-field appearance="legacy" hintLabel="MODELO" class="variable-input" [style.width.px]=450>
              <mat-label>Modelo</mat-label>
              <input matInput formControlName="modelo" type="text" placeholder="MODELO" readonly>
          </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 col-12 mt-2 d-flex justify-content-center">
          <mat-form-field appearance="legacy" hintLabel="AÑO" class="variable-input">
              <mat-label>Año</mat-label>
              <input matInput formControlName="anio" type="number" placeholder="AÑO" readonly>
          </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 col-12 mt-2 d-flex justify-content-center">
          <mat-form-field appearance="legacy" hintLabel="Precio de Venta APEX" class="variable-input">
              <mat-label>Precio de Venta al Público</mat-label>
              <input matInput formControlName="precio" type="text" placeholder="Precio de Venta APEX" readonly>
          </mat-form-field>
      </div>
    </form>
  
    <section class="mt-4  d-flex justify-content-center" tabindex="0"><!-- mat-elevation-z8-->
        <table mat-table [dataSource]="dataSource">
          <!-- Name Column -->
          <ng-container matColumnDef="name" sticky>
            <th mat-header-cell *matHeaderCellDef class="text-header"> </th>
            <td mat-cell *matCellDef="let element" class="name-column text-header"> {{element.name}} </td>
          </ng-container>
      
          <!-- Position Column -->
          <ng-container matColumnDef="plazo_24">
            <th mat-header-cell *matHeaderCellDef class="text-center text-header"> 24 Meses </th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.plazo_24}} </td>
          </ng-container>
      
          <!-- Weight Column -->
          <ng-container matColumnDef="plazo_36">
            <th mat-header-cell *matHeaderCellDef class="text-center text-header"> 36 Meses</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.plazo_36}} </td>
          </ng-container>
      
          <!-- Symbol Column -->
          <ng-container matColumnDef="plazo_48">
            <th mat-header-cell *matHeaderCellDef class="text-center text-header"> 48 Meses</th>
            <td mat-cell *matCellDef="let element" class="text-center"> {{element.plazo_48}} </td>
          </ng-container>
      
          <!-- Star Column -->
          <ng-container matColumnDef="star" stickyEnd>
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon>more_vert</mat-icon>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </section> 
    <div class="mt-4">
      <ol>
        <li>La presente cotización se ofrece al cliente unicamente con fines informativos, requiere autorización y puede ser modificada sin previo aviso.</li>
        <li>Automotriz Apex, SAPI de CV. se reserva el derecho de adquirir los vehículos con el distribuidor de su preferencia.</li>
        <li>Esta cotización no representa ningún compromiso de compra entre las partes.</li>
        <li>La base del cálculo del arrendamiento es el precio de contado del vehículo.</li>
        <li> Incluye la instalación de un GPS por la duración del contrato de arrendamiento.</li>
        <li>El pago inicial no incluye el pago de la primera renta mensual.</li>
        <li>Todas las cantidades expresadas en esta cotización se encuentran en moneda nacional.</li>
        <li *ngIf="finalQuoteData?.vehicle?.seguro != 0">Incluye seguro por la duración del contrato de arrendamiento.</li>
      </ol>
    </div>     
  </div>

  <div class="col-12 d-flex justify-content-center">
    <div class="btn-group" role="group">
      <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        Exportar
      </button>
      <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
        <li><a class="dropdown-item" (click)="downloadPDF()">Generar PDF</a></li>
        <li><a class="dropdown-item" *ngIf="userRolId == 1" (click)="exportDataFINASIST()">Generar Excel</a></li>
      </ul>
    </div>
  </div>
 
</div>