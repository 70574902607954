// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  faker: false,
  cryptoKey: "MzZmYzdjODdhZjdmNDJhMzYzM2Q3OTI1ZDY0NTg0NzVlN2ZiYzRlODgwMWQ5MTNmODZmZjc0MTRiZmQ2N2FkYWIwYTFmNGExY2E2YTQ0NjRiMDg5YTY2NGI1YjI1OWZiOGE3MjQ1YTFmM2RiZTA4YjkwYjc0ZWRiYjQzY2FlNDU=",
  firebase: {
    apiKey: "AIzaSyB2cZdWlcSr659gvRk7CVEdZlzwMMzU2SQ",
    authDomain: "apex-cotizador.firebaseapp.com",
    projectId: "apex-cotizador",
    almacenamientoBucket: "apex-cotizador.appspot.com",
    messagingSenderId: "355615073085",
    appId: "1:355615073085:web:f07115a0b906143cab9b54",
    measurementId: "G-ZG35JC9WZX"
  },
  //url: 'https://localhost:3200/api/quoter/v1',
  url: 'https://api-apex-qa.respaldo.cerebro.media/api/quoter/v1',
  API_KEY: 'NjU4OWVmODNlMGZmNWU2MmQ0ZTM3NjkyOTYyOGVmMjdmZWYxNGJiN2NlN2NkMzViYTNlYTc5Y2U2Y2E1NWVkNA==',
};