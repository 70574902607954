import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AbstractControl } from '@angular/forms';
import { FirebaseError } from 'firebase/app';
import { firstValueFrom } from 'rxjs';
import { environment } from 'environments/environment';
import { UserInterface } from '@data/iterfaces/api/user';
import { UiService } from '@shared/services/ui.service';
import { LoginInterface } from '@data/iterfaces/api/auth.interface';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  loginResponse!: LoginInterface;
  constructor(
    private http: HttpClient,
    private auth: AngularFireAuth,
    private toast: UiService,
  ) { }

  private url: string = environment.url;

  async login(control: AbstractControl) {
    try {
      const userFirebase: any = await this.auth.signInWithEmailAndPassword(control.get('email')?.value, control.get('password')?.value);
      const endPoint = `${this.url}/auth/login/${userFirebase.user?.uid}`;
      let loginResponse = this.http.get(endPoint);
      const response: any = await firstValueFrom(loginResponse);
      this.loginResponse = response;
      this.toast.loading(false, 'Iniciando sesión');

      if (!this.loginResponse.ok) {
        this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
        await this.auth.signOut();
        return false;
      }
      const { user, token, settings } = this.loginResponse;
      localStorage.setItem('rolId', `${user.id_cat_rol}`);
      localStorage.setItem('token', `${token}`);
      localStorage.setItem('user_id', `${user.id}`);
      localStorage.setItem('is_active', `${user.is_active}`);
      localStorage.setItem('settings', `${settings}`);

      return this.loginResponse.user;

    } catch (error) {

      if (error instanceof FirebaseError) {
        this.toast.loading(false, 'Iniciando sesión');
        const newError = (error as FirebaseError);
        switch (newError.code) {
          case 'auth/user-not-found':
            this.toast.error('El correo introducido no está registrado.');
            break;
          case 'auth/wrong-password':
            this.toast.error('La contraseña es incorrecta.');
            break;
          case 'auth/too-many-requests':
            this.toast.error('El acceso a esta cuenta se ha desactivado temporalmente debido a muchos intentos fallidos de inicio de sesión. Puede restaurarlo inmediatamente restableciendo su contraseña o puede intentarlo de nuevo más tarde.');
            break;
          default:
            this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
            break;
        }
      } else {
        await this.auth.signOut();
        this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
      }
      return false;
    }
  }

  getLogedUser() {
    return this.auth.authState;
  }

  async logout() {
    localStorage.clear();
    await this.auth.signOut();
  }

  async register(control: AbstractControl) {
    let status: boolean = false;
    this.toast.loading(true, 'Registrando');
    const endPoint = `${this.url}/auth/user/registerUser`;
    try {
      let user = await this.auth.createUserWithEmailAndPassword(control.get('email')?.value, control.get('password')?.value);
      const body = {
        uid: user.user?.uid,
        email: control.get('email')?.value,
        name: control.get('name')?.value,
      };
      let new_user = this.http.post<UserInterface>(endPoint, body);
      let respUser = await firstValueFrom(new_user);
      this.toast.loading(false, 'Registrando');
      if (respUser.ok) {
        this.toast.success('El usuario se registró exitosamente.');
        status = true;
      } else {
        await this.auth.signOut();
        status = false;
      }
      return status;
    } catch (err) {
      if (err instanceof FirebaseError) {
        this.toast.loading(false, 'Registro');
        let error = (err as FirebaseError);
        switch (error.code) {
          case 'auth/email-already-in-use':
            this.toast.error('El correo ya está registrado, intenta con otro.');
            break;
          default:
            this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
            break;
        }
      } else {
        this.toast.error('Ocurrió un error inesperado, intenta nuevamente.');
      }
      return false;
    }
  }

}

