import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommissionInterface } from '@data/iterfaces/api/commissions.interface';
import { environment } from 'environments/environment';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommissionService {
  constructor(private http: HttpClient) { }

  private url: string = environment.url;

  async getAll() {
    return new Promise<CommissionInterface>(async (resolve) => {
      try {
        setTimeout(() => {
          const endPoint = `${this.url}/actions/get-all-commissions`;
          this.http.get<CommissionInterface>(`${endPoint}`)
            .pipe(
              catchError(error => {
                return of(error as CommissionInterface);
              }))
            .subscribe(response => {
              return resolve(response as CommissionInterface);
            });
        }, 180);
      } catch (error) {
        return resolve(error as CommissionInterface);
      }
    });
  }
}
