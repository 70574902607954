<div class="container-shadow" *ngIf="isDataFinally">
    <div id="quoteDataDiv" #quoteDataDiv>
        <div class="d-flex justify-content-center aligns-items-center">
            <img class="img-fluid img-report" src="../../../../assets/images/logo-color.png" alt="">
        </div>
        <hr>
        <h3 class="text-center title-report">COTIZACIÓN</h3>
        <form class="form-group row d-flex justify-content-center mt-4">
            <div class="mt-4">
                <div class="row">
                    <div class="col-md-6">
                        <h4 class="text-start">
                            <span class="subtitle-report">Fecha de cotización:</span>
                            <span class="span_bold">{{date }}</span>
                        </h4>
                    </div>
                    <div class="col-md-6">
                        <h4 class="text-end">
                            <span class="subtitle-report">Folio:</span>
                            <span class="span_bold">{{folio}}</span>
                        </h4>
                    </div>
                </div>
            </div>
            <p class="text-start">
                Por medio de la presente a nombre de Arrendadora Apex le hacemos llegar su cotización de arrendamiento
                para el vehículo:
            </p>

            <h4 class="text-start subtitle-report">Detalles del Vehículo:</h4>
            <div class="container">
                <div class="row">
                    <div class="col-4">
                        <p class="details">Marca:</p>
                        <p>
                            <span>{{marca}}
                                <hr>
                            </span>
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="details">Modelo:</p>
                        <p>
                            <span>{{modelo}}
                                <hr>
                            </span>
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="details">Año:</p>
                        <p>
                            <span>{{year}}
                                <hr>
                            </span>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <p class="details">Precio de Venta al Público:</p>
                        <p>
                            <span>{{value_active}}
                                <hr>
                            </span>
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="details">Precio de Venta APEX:</p>
                        <p>
                            <span>{{value_active}}
                                <hr>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </form>
        <section class="mt-4  d-flex justify-content-center" tabindex="0">
            <table mat-table [dataSource]="quoterData">
                <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.value">
                    <th mat-header-cell *matHeaderCellDef
                        [ngClass]="i === 0 ? 'text-header' : 'text-center text-header'">
                        {{column.title}}
                    </th>
                    <td mat-cell *matCellDef="let element"
                        [ngClass]="i === 0 ? 'name-column text-header' : 'text-center'">
                        {{element[column.value]}}
                    </td>
                </ng-container>
                <thead>
                    <tr mat-header-row *matHeaderRowDef="getColumns(); sticky: true;"></tr>
                </thead>
                <tr mat-row *matRowDef="let row; columns: getColumns();"></tr>
            </table>
        </section>
        <div class="mt-4">
            <ol>
                <li>La presente cotización se ofrece al cliente unicamente con fines informativos, requiere autorización
                    y puede ser modificada sin previo aviso.</li>
                <li>Automotriz Apex, SAPI de CV. se reserva el derecho de adquirir los vehículos con el distribuidor de
                    su preferencia.</li>
                <li>Esta cotización no representa ningún compromiso de compra entre las partes.</li>
                <li>La base del cálculo del arrendamiento es el precio de contado del vehículo.</li>
                <li> Incluye la instalación de un GPS por la duración del contrato de arrendamiento.</li>
                <li>El pago inicial no incluye el pago de la primera renta mensual.</li>
                <li>Todas las cantidades expresadas en esta cotización se encuentran en moneda nacional.</li>
                <li>El seguro será otorgado por la arrendadora, por cuenta y cargo del arrendatario, durante la vigencia
                    del contrato, el cual <b *ngIf="insurance_value <= 0">NO</b>
                    esta incluido en la cotización.
                </li>
            </ol>
        </div>
    </div>
    <div class="col-12 d-flex justify-content-center">
        <div class="btn-group" role="group">
            <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                aria-expanded="false">
                Exportar
            </button>
            <ul class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                <li><a class="dropdown-item pointer" (click)="downloadPDF()">Generar PDF</a></li>
                <li *ngIf="userRolId === 1">
                    <a class="dropdown-item pointer" (click)="downloadPDFBill()">Descargar Factura </a>
                </li>
                <li *ngIf="userRolId === 1">
                    <a class="dropdown-item" target="_blank" href="{{url}}">
                        Descargar Excel
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>