import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HistoryInterface } from '@data/iterfaces/api/history.interface';
import { QuoterResponse } from '@data/iterfaces/api/quoterpy';
import { environment } from 'environments/environment';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private http: HttpClient,
  ) { }

  private url: string = environment.url;

  async index(params: any) {
    return new Promise<HistoryInterface>(async (resolve) => {
      try {
        setTimeout(() => {
          const { user, search, sort, direction, limit, page, } = params;
          const endPoint = `${this.url}/admin/history/get-all/${user}/${search}/${sort}/${direction}/${limit}/${page}`;
          this.http.get<HistoryInterface>(`${endPoint}`)
            .pipe(
              catchError(error => {
                return of(error as HistoryInterface);
              }))
            .subscribe(response => {
              return resolve(response as HistoryInterface);
            });
        }, 180);
      } catch (error) {
        return resolve(error as HistoryInterface);
      }
    });
  }

  async generateQuoter(historyId: number) {
    return new Promise<QuoterResponse>(async (resolve) => {
      try {
        setTimeout(() => {
          const endPoint = `${this.url}/test/generate-quoter-history/${historyId}`;
          this.http.get<QuoterResponse>(`${endPoint}`)
            .pipe(
              catchError(error => {
                return of(error as QuoterResponse);
              }))
            .subscribe(response => {
              return resolve(response as QuoterResponse);
            });
        }, 180);
      } catch (error) {
        return resolve(error as QuoterResponse);
      }
    });
  }
}
