import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { transpose } from 'csv-transpose';
import { UiService } from '@shared/services/ui.service';

@Component({
  selector: 'app-final-report',
  templateUrl: './final-report.component.html',
  styleUrls: ['./final-report.component.sass']
})
export class FinalReportComponent implements OnInit {
  @ViewChild('quoteDataDiv', { static: false }) quoteDataDiv!: ElementRef;
  @Input() finalQuoteData: any;
  dataFINASIST !: [any];
  userRolId!: any;
  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private uiService: UiService
  ) { }

  formVehicle: FormGroup = this.formBuilder.group({
    marca: [null],
    modelo: [null],
    anio: [null],
    precio: [null]
  });

  displayedColumns: string[] = ['name', 'plazo_24', 'plazo_36', 'plazo_48'];
  VARIABLES_REPORTE: any;
  dataSource!: any;

  ngOnInit(): void {
    this.userRolId = localStorage.getItem('rolId');
    this.userRolId = parseInt(this.userRolId);
  }

  atributesNames: any = {
    plazo: 'Plazo',
    pago_incial_total: 'Pago Inicial Total',
    activo_iva: 'IVA',
    pago_incial_total_con_iva: 'Pago Inicial Total con IVA',
    renta_mensual: 'Renta Mensual',
    seguro_mensual: 'Seguro Mensual',
    renta_mas_seguro: 'Renta + Seguro',
    seguro_iva: 'IVA',
    pago_mensual_total_con_iva: 'Pago Mensual Total con IVA',
    pago_inicial_mas_primera_renta: 'Pago Inicial + Primera Renta con IVA',
    valor_porcentaje_residual: '',
    valor_residual: 'Valor Residual',
    valor_residual_con_iva: 'Valor Residual con IVA',
    pago_inicial_estimado_rg: 'Primera renta calculada'
  }

  ngOnChanges(changes: SimpleChanges) {
    this.proccessQuoteData(this.finalQuoteData);
  }

  proccessQuoteData(data: any) {
    if (data != undefined) {
      let vehicleData = data?.vehicle;
      this.formVehicle.patchValue(vehicleData);
      /* Table Report */
      data = data?.statistics;
      data = data.map((term: any) => {
        term.cotizacion_final.pago_incial_total = `$ ${term.cotizacion_final.pago_incial_total.toLocaleString()}`;
        term.cotizacion_final.activo_iva = `$ ${term.cotizacion_final.activo_iva.toLocaleString()}`;
        term.cotizacion_final.pago_incial_total_con_iva = `$ ${term.cotizacion_final.pago_incial_total_con_iva.toLocaleString()}`;
        term.cotizacion_final.renta_mensual = `$ ${term.cotizacion_final.renta_mensual.toLocaleString()}`;
        term.cotizacion_final.seguro_mensual = `$ ${term.cotizacion_final.seguro_mensual.toLocaleString()}`;
        term.cotizacion_final.renta_mas_seguro = `$ ${term.cotizacion_final.renta_mas_seguro.toLocaleString()}`;
        term.cotizacion_final.seguro_iva = `$ ${term.cotizacion_final.seguro_iva.toLocaleString()}`;
        term.cotizacion_final.pago_mensual_total_con_iva = `$ ${term.cotizacion_final.pago_mensual_total_con_iva.toLocaleString()}`;
        term.cotizacion_final.pago_inicial_mas_primera_renta = `$ ${term.cotizacion_final.pago_inicial_mas_primera_renta.toLocaleString()}`;
        term.cotizacion_final.valor_residual = `$ ${term.cotizacion_final.valor_residual.toLocaleString()}`;
        term.cotizacion_final.valor_residual_con_iva = `$ ${term.cotizacion_final.valor_residual_con_iva.toLocaleString()}`;
        term.cotizacion_final.valor_porcentaje_residual = `${Math.round((term.cotizacion_final.valor_porcentaje_residual) * 10000) / 100} %`;
        if (this.userRolId == 1 && term.pago_inicial_estimado_rg) term.cotizacion_final.pago_inicial_estimado_rg = `$ ${term?.pago_inicial_estimado_rg.toLocaleString()}`;
        return term;
      });

      this.dataFINASIST = data.map((term: any) => {
        return term.datos_FINASIST;
      });
      let keysObject = Object.keys(data[0]?.cotizacion_final);
      if (keysObject.length > 1) {
        let quoteTableData = keysObject.map(quoteAtribute => {
          return {
            name: this.atributesNames[quoteAtribute],
            plazo_24: data[0].cotizacion_final[quoteAtribute],
            plazo_36: data[1].cotizacion_final[quoteAtribute],
            plazo_48: data[2].cotizacion_final[quoteAtribute],
          }
        });
        this.setReportData(quoteTableData);
      }
    }
  }

  setReportData(data: any) {
    this.VARIABLES_REPORTE = data;
    this.dataSource = new MatTableDataSource(this.VARIABLES_REPORTE);
    this.cdr.detectChanges();
  }

  /*************************** Export Reports *******************/
  downloadPDF() {
    try {
      const DATA = this.quoteDataDiv.nativeElement;
      let w = DATA.offsetWidth;
      let h = DATA.offsetHeight;
      const options = {
        background: 'white',
        scale: 3
      };
      html2canvas(DATA, options).then((canvas) => {
        let img = canvas.toDataURL("image/jpeg", 1);
        let doc = new jsPDF('p', 'pt', [h, w]);
        doc.addImage(img, 'JPEG', 30, 15, w - 60, h - 30);
        doc.save('cotizacion_APEX.pdf');
      });
    } catch (error) {
      console.log(error);
      this.uiService.error("Ocurrió un error al tratar de descargar el archivo, inténtelo más tarde, si el problema persiste, comuníquese con el administrador.");
    }
  }

  exportDataFINASIST() {
    try {
      let i = 0;
      let data_finasist: any = this.dataFINASIST.map(data => {
        data['-----------------'] = '-----------------';
        data.Renta = `${this.finalQuoteData?.active_rate[i].renta}%`;
        data.Irr = `${this.finalQuoteData?.active_rate[i].irr}%`;
        data['Tasa Activa Seguro'] = `${this.finalQuoteData?.active_rate[i].tasaActivaSeguro}%`;
        data['              '] = '              ';
        data['               '] = '               ';
        data['                '] = '                ';
        i++;
        return data;
      });

      let csvFINASIST = this.castJsonArrayToCSVstring(data_finasist);
      const transposeCsvFINASIST = transpose(csvFINASIST, ',');
      let clientQuote = this.finalQuoteData?.statistics.map((element: any) => {
        let data = {
          "Plazo": element?.cotizacion_final?.plazo,
          "Pago inicial total": element?.cotizacion_final?.pago_incial_total,
          "IVA": element?.cotizacion_final?.activo_iva,
          "Pago Inicial Total con IVA": element?.cotizacion_final?.pago_incial_total_con_iva,
          "Renta Mensual": element?.cotizacion_final?.renta_mensual,
          "Seguro Mensual": element?.cotizacion_final?.seguro_mensual,
          "Renta + Seguro": element?.cotizacion_final?.renta_mas_seguro,
          "IVA Seguro": element?.cotizacion_final?.seguro_iva,
          "Pago Mensual Total con IVA": element?.cotizacion_final?.pago_mensual_total_con_iva,
          "Pago Inicial + Primera Renta con IVA": element?.cotizacion_final?.pago_inicial_mas_primera_renta,
          " ": element?.cotizacion_final?.valor_porcentaje_residual,
          "Valor Residual": element?.cotizacion_final?.valor_residual,
          "Valor Residual con IVA": element?.cotizacion_final?.valor_residual_con_iva,
        }
        return data;
      });
      let csvClientQuote = this.castJsonArrayToCSVstring(clientQuote);
      const transponseCsvClientQuote = transpose(csvClientQuote, ',');
      const final_csv = transposeCsvFINASIST + transponseCsvClientQuote;

      let blobCSV = new Blob(["\uFEFF" + final_csv], { type: 'text/csv;charset=UTF-8' })
      saveAs(blobCSV, "informacion-FINASIST.csv");
    } catch (error) {
      console.log(error);
      this.uiService.error("Ocurrió un error al tratar de descargar el archivo, inténtelo más tarde, si el problema persiste, comuníquese con el administrador.");
    }
  }

  /*************************** Aux Functions *******************/
  castJsonArrayToCSVstring(objArray: [any]) {
    const replacer = (key: any, value: any) => value === null ? '' : value
    const header = Object.keys(objArray[0])
    const csv = [header.join(','), ...objArray.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))].join('\r\n')
    return csv;
  }
}
