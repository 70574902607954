import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private cryptoKey: string = environment.cryptoKey;

  private decrypt(data: string): any {
    let dataDecrypt = AES.decrypt(data, this.cryptoKey);
    return JSON.parse(dataDecrypt.toString(enc.Utf8));
  }

  async getSettings() {
    const settings = localStorage.getItem('settings') || '';
    const dataDecrypted = JSON.parse(this.decrypt(settings));
    return dataDecrypted;
  }
}
