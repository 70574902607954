import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@data/services/auth/auth.service';

@Component({
  selector: 'app-user-session-btn',
  templateUrl: './user-session-btn.component.html',
  styleUrls: ['./user-session-btn.component.sass']
})
export class UserSessionBtnComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  userLoged = this.authService.getLogedUser();

  logout(){
    this.authService.logout();
    this.router.navigate(['/auth/login']); 
  }

}
