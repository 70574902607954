<div class="container-shadow">
  <form class="text-center" (ngSubmit)="generateQuote()" [formGroup]="formCotizador">
    <div class="d-flex justify-content-center aligns-items-center">
      <img class="img-fluid img-report" src="../../../../assets/images/logo-color.png" alt="">
    </div>
    <div class="form-group row d-flex justify-content-center">
      <h3 class="text-center title-report">CONSTANTES <span>(Sólo lectura)</span> </h3>
      <div class="col-10 row variables-block justify-content-center" *ngIf="userRolId == 1">
        <p class="col-lg-3 col-4">
          <mat-form-field appearance="legacy" class="constant-input">
            <mat-label>IVA</mat-label>
            <input matInput placeholder="IVA" formControlName="iva" readonly>
          </mat-form-field>
        </p>
        <p class="col-lg-3 col-4">
          <mat-form-field appearance="legacy" class="constant-input">
            <mat-label>IRR Mínimo</mat-label>
            <input matInput placeholder="IRR Mínimo" formControlName="irrminimo" readonly>
          </mat-form-field>
        </p>
        <p class="col-lg-3 col-4">
          <mat-form-field appearance="legacy" class="constant-input">
            <mat-label>UDI</mat-label>
            <input matInput placeholder="UDI" formControlName="udi" readonly>
          </mat-form-field>
        </p>
        <p class="col-lg-3 col-4">
          <mat-form-field appearance="legacy" class="constant-input">
            <mat-label>Comisión</mat-label>
            <input matInput placeholder="Comisión" formControlName="comision" readonly>
          </mat-form-field>
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-10" *ngIf="userRolId == 1">
        <table mat-table [dataSource]="dataSourceTA" class="table-tasa-activa" *ngIf="TASA_ACTIVA_RENTA != undefined">
          <!-- Meses Column -->
          <ng-container matColumnDef="meses">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Meses </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Tasa Activa Column -->
          <ng-container matColumnDef="tasaActiva">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Tasa Activa Renta</th>
            <td mat-cell *matCellDef="let element"> {{element.value}} % </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsTasas"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsTasas;"></tr>
        </table>
      </div>

      <div class="col-lg-6 col-md-6 col-10">
        <table mat-table [dataSource]="dataSourceVR" class="table-tasa-activa" *ngIf="VALOR_RESIDUAL != undefined">
          <!-- Meses Column -->
          <ng-container matColumnDef="meses">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Meses </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Tasa Activa Column -->
          <ng-container matColumnDef="valorResidual">
            <th mat-header-cell *matHeaderCellDef class="text-center"> Valor Residual </th>
            <td mat-cell *matCellDef="let element"> {{element.value}} % </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsResidual"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsResidual;"></tr>
        </table>
      </div>
    </div>

    <h3 class="text-center title-report">VARIABLES</h3>

    <div class="form-group row d-flex justify-content-center">
      <h4 class="text-start subtitle-report">Detalles del Vehículo:</h4>
      <div class="col-lg-4 col-md-4 col-12">
        <mat-form-field hintLabel="Máximo 80 carácteres" appearance="fill" class="variable-input">
          <mat-label>Marca</mat-label>
          <input matInput #marcaInput maxlength="80" formControlName="marca" type="text" placeholder="Ingrése la marca">
          <mat-hint align="end">{{marcaInput.value.length || 0}}/80</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <mat-form-field hintLabel="Máximo 80 carácteres" appearance="fill" class="variable-input">
          <mat-label>Modelo</mat-label>
          <input matInput #modeloInput maxlength="80" formControlName="modelo" type="text"
            placeholder="Ingrése el modelo">
          <mat-hint align="end">{{modeloInput.value.length || 0}}/80</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <mat-form-field hintLabel="Longitud de 4 carácteres" appearance="fill" class="variable-input">
          <mat-label>Año</mat-label>
          <input matInput #anioInput [minLength]="4" [maxLength]="4" formControlName="anio" type="number"
            placeholder="Ingrése el año">
          <mat-hint align="end">{{anioInput.value.length || 0}}/4</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row d-flex justify-content-center mt-4">
      <!--<h4 class="text-start subtitle-report">Tasa Activa:</h4>-->
      <div class="col-lg-4 col-md-4 col-12">
        <mat-form-field appearance="fill" floatLabel="always" class="variable-input">
          <mat-label>Precio Original</mat-label>
          <input matInput type="number" formControlName="precioOriginal"
            (keyup)="applyIva($any($event.target).value, 0)" class="example-right-align" placeholder="0">
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <mat-form-field appearance="fill" hintLabel="Se rellenará automáticamente" floatLabel="always"
          class="variable-input">
          <mat-label>Precio APEX (Sin IVA/1.16)</mat-label>
          <input matInput type="number" value="0" formControlName="precioApex" class="example-right-align"
            placeholder="0" readonly>
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <mat-form-field appearance="fill" hintLabel="Se rellenará automáticamente" floatLabel="always"
          class="variable-input">
          <mat-label>Precio Público (Sin IVA/1.16)</mat-label>
          <input matInput type="number" value="0" formControlName="precioPublico" class="example-right-align"
            placeholder="0" readonly>
          <span matPrefix>$&nbsp;</span>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row d-flex justify-content-center mt-4">
      <h4 class="text-start subtitle-report">Seguro:</h4>
      <div class="col-lg-4 col-md-4 col-12">
        <mat-form-field hintLabel="Máximo 20 carácteres" appearance="fill" class="variable-input">
          <mat-label>Compañía</mat-label>
          <input matInput #companiaInput maxlength="40" type="text" placeholder="Ingrése la compañía">
          <mat-hint align="end">{{companiaInput.value.length || 0}}/40</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <mat-form-field hintLabel="Máximo 20 carácteres" appearance="fill" class="variable-input">
          <mat-label>Prima</mat-label>
          <input matInput #primaInput type="number" formControlName="primaOriginal" [minLength]="1" [maxLength]="100"
            placeholder="Ingrése la prima" (keyup)="applyIva($any($event.target).value, 1)">
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <mat-form-field hintLabel="Se rellenará automáticamente" appearance="fill" class="variable-input">
          <mat-label>Prima Total</mat-label>
          <input matInput #primaTotalInput type="number" formControlName="primaTotal"
            placeholder="Ingrése el campo prima" readonly="">
        </mat-form-field>
      </div>
    </div>
    <div class="text-center align-items-center">
      <button class="btn quoter-btn" type="submit">Cotizar</button>
    </div>

    <div class="row" [formGroup]="formReverseQuote">
      <div class="col-0 col-lg-6">
      </div>
      <div class="col-12 col-lg-6">
        <h4 class="text-end subtitle-report">¿Desea agregar un pago incial mayor</h4>
        <div class="col-12 d-flex justify-content-end">
          <mat-form-field appearance="fill" class="variable-input">
            <mat-label>Monto</mat-label>
            <input matInput #montoMayor type="number" formControlName="pago_inicial" type="text"
              placeholder="Ingrése el monto">
          </mat-form-field>

          <button class="btn quoter-btn" type="button" style="margin: 1rem;"
            (click)="generateReverseQuote()">Cotizar</button>
        </div>
      </div>
    </div>

    <div class="form-group row d-flex justify-content-center mt-4"
      *ngIf="TASA_ACTIVA_SEGURO != undefined && userRolId == 1">
      <h3 class="text-center title-report">TASA ACTIVA</h3>
      <!--<h4 class="text-start subtitle-report">Tasa Activa Seguro:</h4>-->
      <table mat-table mat-table [dataSource]="dataSourceTAS" class="table-cotizacion">
        <!-- Meses Column -->
        <ng-container matColumnDef="meses">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Meses </th>
          <td mat-cell *matCellDef="let element"> {{element.meses}} </td>
        </ng-container>

        <!-- Renta Column -->
        <ng-container matColumnDef="renta">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Renta </th>
          <td mat-cell *matCellDef="let element"> {{element.renta}} %</td>
        </ng-container>

        <!-- IRR Column -->
        <ng-container matColumnDef="irr">
          <th mat-header-cell *matHeaderCellDef class="text-center"> IRR </th>
          <td mat-cell *matCellDef="let element"> {{element.irr}} %</td>
        </ng-container>

        <!-- Tasa Activa Column -->
        <ng-container matColumnDef="tasaActivaSeguro">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Tasa Activa Seguro</th>
          <td mat-cell *matCellDef="let element"> {{element.tasaActivaSeguro}} % </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsTasaRenta"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsTasaRenta;"></tr>
      </table>
    </div>
  </form>
</div>