<div class="row w-100 p-0">
    <div class="col-12 w-100 p-0">
      <div  *ngIf="userLoged|async as user;">
        <div class="dropdown">
          <input type="checkbox" id="dropdown">
        
          <label class="dropdown__face" for="dropdown">
            <img class="user-icon img-fluid" style="font-size: 80%;" src="./../../../../assets/images/userBoton.png" alt="">
            <span class="text-session-btn">{{ user.displayName?user.displayName : user.email}}</span>
            <div class="dropdown__arrow"></div>
          </label>
        
          <ul class="dropdown__items">
            <li (click)="logout()" class="font-user">
                <mat-icon class="mt-3">power_settings_new</mat-icon>
                <span class="text-session-btn" style="margin-left: 1rem;">Cerrar sesión</span> 
            </li>        
          </ul>
        </div>
      </div>
    </div>
</div>