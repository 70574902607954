import { UiService } from '@shared/services/ui.service';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Column, QuoterResponse } from '@data/iterfaces/api/quoterpy';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-quoterfinally',
  templateUrl: './quoterfinally.component.html',
  styleUrls: ['./quoterfinally.component.sass']
})
export class QuoterfinallyComponent implements OnInit {
  @ViewChild('quoteDataDiv', { static: false }) quoteDataDiv!: ElementRef;
  @Input() finalQuoteData: any;
  public userRolId!: any;
  public isDataFinally!: boolean;
  public marca!: string;
  public modelo!: string;
  public year!: number;
  public insurance_value!: number;
  public company!: string;
  public url!: string;
  public base64Bill!: string;
  public value_active!: string;
  public quoterData: any;
  public report: any;
  public folio!: any;
  public date: any;
  public displayedColumns: string[] = ['0', '1', '2', '3'];
  public response!: QuoterResponse;
  public columns!: Column[];

  constructor(
    private cdr: ChangeDetectorRef,
    private uiService: UiService
  ) { }

  ngOnInit(): void {
    this.userRolId = localStorage.getItem('rolId');
    this.userRolId = parseInt(this.userRolId);
    this.isDataFinally = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.finalQuoteData) {
      this.response = this.finalQuoteData;
      this.isDataFinally = this.response.ok;
      if (this.isDataFinally) {
        this.columns = this.response.quoter.quoterFinally.columns;
        this.quoterFinally(this.response.quoter.quoterFinally.dataFinally);
        this.setDetailsData(this.response);
        this.insurance_value = this.response.quoter.insurance_value;
        this.url = this.response.quoter.url;
        this.folio = this.response.quoter.folio;


        this.date = this.dateSubstringFolio();
        this.base64Bill = this.response.quoter.base64Bill;
      }
    }
  }

  dateSubstringFolio() {
    const dateSubstringFolio = this.folio.substring(5, 13);
    const day = dateSubstringFolio.substring(0, 2);
    const month = dateSubstringFolio.substring(2, 4);
    const year = dateSubstringFolio.substring(4);
    return `${day}/${month}/${year}`;
  }

  quoterFinally = (data: any): any => (this.setQuoterData(data));

  setQuoterData = (data: any): any => (this.quoterData = new MatTableDataSource(data));

  getColumns = (): string[] => (this.columns.map(columna => columna.value));

  setDetailsData(data: any) {
    let details: QuoterResponse = data;
    this.marca = details.quoter.marca;
    this.value_active = details.quoter.value_active;
    this.modelo = details.quoter.modelo;
    this.year = details.quoter.year;
    this.company = details.quoter.company;
  }

  downloadPDF() {
    const options = { background: 'white', scale: 3 };
    const data = this.quoteDataDiv.nativeElement;
    const thead = data.querySelector('thead');
    const parent = thead.parentNode;
    parent.removeChild(thead);
    html2canvas(data, options)
      .then((canvas) => {
        const img = canvas.toDataURL("image/jpeg", 1);
        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'pt',
          format: [data.offsetHeight, data.offsetWidth]
        });
        pdf.addImage(img, 'JPEG', 30, 15, data.offsetWidth - 60, data.offsetHeight - 30);
        pdf.save('cotizacion_apex.pdf');
        parent.insertBefore(thead, parent.children[1]);
      })
      .catch((error) => {
        console.error(error);
        parent.insertBefore(thead, parent.children[1]);
        this.uiService.error("Ocurrió un error al tratar de descargar el archivo, inténtelo más tarde.");
      });
  }

  async downloadPDFBill() {
    await this.downloadFile();
  }

  async downloadFile() {
    const link = `data:application/octet-stream;base64,${this.base64Bill}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = link;
    downloadLink.download = 'apex_factura.pdf';
    downloadLink.click();
  }
}